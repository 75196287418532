import React from 'react';

import './Banner.scss';

const Banner = ({data}) => {
  return (
    <section id="biography-banner" style={{backgroundImage: `url(${data.background.sourceUrl})`}}>
      <div className="container">
        <div className="row">
          <div className="col-md-10 mx-auto">
            <div className="description">
              <h1>{data.title}</h1>
              <h3>{data.subtitle}</h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
