import React from 'react';
import './Description.scss';

const Description = ({data}) => {
  return (
    <section id="biography-description">
      <div className="container">
        <div className="row">
          <div className="col-lg-10 mx-auto">
            <div className="row">
              <div className="text" dangerouslySetInnerHTML={{__html: data}}>
              </div>
            </div>
            <div className="row">                
              <div className='button-container'>
                <a href='https://entes-admin.whiz.one/wp-content/uploads/2022/08/Entes-CV-ES.pdf' className='buttons' target={"_blank"}>
                  <button className="btn btn--stroke active tab-button">Ver CV</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Description;
