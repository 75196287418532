import React from 'react';
import Seo from "../components/seo";
import Layout from '../layouts/en.jsx';
import { graphql, useStaticQuery } from "gatsby";
import { Banner, DescriptionEn } from '@components/pages/biography';

const BiographyPage = () => {
  const {wpPage}  = useStaticQuery(graphql`
      query BioEnglish {
        wpPage(slug: {eq: "entes-bio"}) {
          id
          date
          title
          slug
          status
          featuredImage {
            node {
              sourceUrl
            }
          }
          language {
            locale
            name
            slug
          }
          content
          fieldsBio {
            header {
              title
              subtitle
              background {
                altText
                link
                sourceUrl
              }
            }
          }
          language {
            slug
            name
            locale
            id
            homeUrl
            code
          }
          seo {
            canonical
            metaDesc
            opengraphDescription
            opengraphImage {
              altText
              link
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphSiteName
            opengraphPublisher
            opengraphTitle
            opengraphType
            opengraphUrl
            schema {
              raw
            }
            title
            twitterDescription
            twitterImage {
              altText
              link
            }
            twitterTitle
          }
        }
      }
      `);
  const { fieldsBio, seo } = wpPage;
  return (
    <>
      <Seo seo={seo}
           author={false}
           lang={wpPage.language.locale}
           thumbnail={wpPage.featuredImage ? wpPage.featuredImage.node.sourceUrl : ''}
           pathname="/en/bio"/>
      <Layout pageId="biography" scrollTop={true}>
        <Banner data={fieldsBio.header}/>
        <DescriptionEn data={wpPage.content} />
      </Layout>
    </>
  );
};

export default BiographyPage;
